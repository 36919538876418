
import { Component, Prop, Vue } from 'vue-property-decorator';
import DoughnutChart from '@/lib/charts/doughnutChart';
import { getConfigEnv } from '@/utils/helpers';
import axiosClient from '@/lib/rest/axiosClient';

@Component({
  components: {
    DoughnutChart,
  },
})
export default class GptOverviewCluster extends Vue {
  @Prop({ type: Object, required: true }) tasq?: any;

  rankPosition = '';

  chartDataUpdated = 0;

  loadingClusterOverview = false;

  rankPercentile = 0;

  chartOptions = {
    responsive: true,
    borderRadius: 50,
    maintainAspectRatio: false,
    events: [],
    loaded: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 90,
    tooltips: {
      bodyFontSize: 7,
    },
  };

  chartData = {
    name: 'Tasqs',
    labels: ['Active', 'Completed', 'Yet to start'],
    datasets: [
      {
        name: 'Tasqs',
        labels: 'Tasqs',
        backgroundColor: ['#273e55', '#FFFFFF'],
        hoverBackgroundColor: ['#273e55', '#FFFFFF'],
        borderColor: ['#273e55', '#FFFFFF'],
        hoverBorderColor: '#FFFFFF',
        hoverBorderWidth: 1,
        borderAlign: 'outer',
        data: [1, 1],
        borderWidth: 2,
        borderRadius: 50,
      },
    ],
  };

  get operatorList() {
    return [
      { operatorID: 8, operatorName: 'petronas', appName: 'petronascanada' },
      { operatorID: 7, operatorName: 'taprock', appName: 'taprock' },
      { operatorID: 6, operatorName: 'pdce', appName: 'pdc' },
      { operatorID: 2, operatorName: 'SWN', appName: 'swn' },
      { operatorID: 1, operatorName: 'Extraction', appName: 'civitas' },
      { operatorID: 9, operatorName: 'demo', appName: 'demo' },
      { operatorID: 5, operatorName: 'caerus', appName: 'caerus' },
    ];
  }

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  async mounted() {
    this.loadingClusterOverview = true
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    const { data: existingRecord }: any = await axiosClient.get(
      '/analysis/well-common-issues',
      {
        params: {
          node_name: this.tasq.wellName,
          operator: operatorDetails['operatorName'],
        },
      }
    );

    console.log('dfasfasdfdasfsfd');

    console.log(existingRecord);

    const {
      data: { percentile_rank: percentileRank, place: position },
    }: any = await axiosClient.get('/analysis/well-percentile-rank', {
      params: {
        node_name: this.tasq.wellName,
        operator: operatorDetails['operatorName'],
      },
    });


    console.log(percentileRank);

    this.rankPercentile = percentileRank;

    const remainingRank = 1 - this.rankPercentile

    console.log(remainingRank  * 100);
    this.rankPosition = `${Math.round((percentileRank * 100))}%`

    this.chartData['datasets'][0].data = [remainingRank, this.rankPercentile, ]
    console.log(this.chartData);
    this.chartDataUpdated += 1;
    this.loadingClusterOverview = false
  }
}
